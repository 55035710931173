package content

import dev.fritz2.components.alert
import dev.fritz2.dom.html.RenderContext
import kotlinx.coroutines.ExperimentalCoroutinesApi
import stores.DataStore
import kotlin.time.ExperimentalTime

@ExperimentalTime
@ExperimentalCoroutinesApi
fun RenderContext.unauthorizedContent() {
    alert {
        severity { warning }
        icon { warning }
        title("Inte inloggad")
        content("Se till att logga in för att få tillgång till allt!")
    }
}