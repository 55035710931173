import dev.fritz2.styling.params.BasicParams
import dev.fritz2.styling.params.Style
import kotlinx.coroutines.ExperimentalCoroutinesApi

@ExperimentalCoroutinesApi
val disabledStyle: Style<BasicParams> = {
    children("&[disabled]") {
        opacity { "50%" }
        children("> *") {
            textDecoration { "line-through" }
            css("filter: grayscale(100%);")
        }
    }
}