package content

import dev.fritz2.dom.html.RenderContext
import dev.fritz2.routing.Router
import rawHtmlDiv

fun RenderContext.customMarkdownContentOrDefaultInfo(sections: Map<String, String>, site: String, router: Router<String>) {
    val content = sections[site]

    if (content == null) {
        router.navTo("information")
    } else {
        rawHtmlDiv(content)
    }
}