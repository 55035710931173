package content

import dev.fritz2.components.icon
import dev.fritz2.dom.html.RenderContext
import dev.fritz2.styling.style
import kotlinx.coroutines.ExperimentalCoroutinesApi
import weddingIcon

@ExperimentalCoroutinesApi
fun RenderContext.brand() {
    icon({
        size { "2rem" }
        margins { right { normal } }
    }) { def(weddingIcon) }
    span {
        style {
            fontWeight { semiBold }
            fontSize { large }
        }
        +"Bröllop x Londögård"
    }
}