import content.*
import dev.fritz2.binding.RootStore
import dev.fritz2.components.appFrame
import dev.fritz2.components.toast.ToastComponent
import dev.fritz2.dom.html.render
import dev.fritz2.routing.decodeURIComponent
import dev.fritz2.routing.router
import kotlinx.browser.window
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onEach
import stores.DataStore
import stores.RestApiStore
import kotlin.time.ExperimentalTime

// TODO add spinner for validation

// TODO invalidate auth rather on invalid requests (401), so that it's always kept until that time.

// TODO create like "Ghost" Variants that actually is copies of the DataStore.
//  We should make sure that if DataStore updates, these also update.
//  These handle the local changes and sends that to the RestApi.
//  This also means we can simpler track history.
//  Finally we should create a syncing state between backend/frontend on gifts, to make sure we dont doublecheck!
//  We should also allow error msg's.


// TODO  Code extractions!

@ExperimentalTime
@ExperimentalStdlibApi
@ExperimentalCoroutinesApi
fun main() {
    val tmpUsername = RootStore("")
    val tmpPw = RootStore("")
    val router = router("information")
    var beenHere = false

    // regexp is actually cleaner but slower... Might be better off with it though.
    val splitSite = window.location.href
        .split(window.location.host)[1]
        .split('?', ':')

    if (splitSite.size == 3) {
        RestApiStore.setLogin(decodeURIComponent(splitSite[1]) to decodeURIComponent(splitSite[2]))
    }

    if (!beenHere) {
        DataStore.getData()
        beenHere = true
    }

    render { // using id selector here, leave blank to use document.body
        DataStore
            .data
            .onEach {
                ToastComponent.closeAllToasts()()
                DataStore.showToastForDataType()
            }
            .render { data ->
                val customSections = (kotlin.runCatching { data.customMarkdownSections }.getOrNull() ?: emptyMap())
                    .mapKeys { (key, _) -> key.lowercase() }

                appFrame {
                    brand { brand() }
                    header { headerContent(router.data) }
                    navigation { navContent(customSections.keys) }
                    complementary { footer() }
                    content {
                        router.data
                            .map { it.lowercase() }
                            .renderElement { site ->
                                div {
                                    when (site) {
                                        "information" -> mainContent(DataStore.data.map { data -> data.information })
                                        "navigation" -> mapContent()
                                        "login" -> loginContent(tmpUsername, tmpPw, RestApiStore.setLogin, router)
                                        "rsvp" -> rsvpContent()
                                        "gifts" -> giftContent(DataStore)
                                        "contact" -> contactContent(DataStore.data.map { data -> data.contact })
                                        "photos" -> photoContent()
                                        else -> customMarkdownContentOrDefaultInfo(customSections, site, router)
                                    }
                                }
                            }
                    }
                }
            }
    }
}