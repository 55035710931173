package content

import dev.fritz2.dom.html.RenderContext
import dev.fritz2.styling.span
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map

@ExperimentalCoroutinesApi
@ExperimentalStdlibApi
fun RenderContext.headerContent(title: Flow<String>) {
    span({
        fontWeight { semiBold }
        fontSize { large }
    }) {
        title
            .map { route ->
                route
                    .replace("rsvp", "OSA")
                    .replace("contact", "Kontaktinformation")
                    .replace("gifts", "Tips på Bröllopsgåvor")
                    .replaceFirstChar(Char::uppercase)
                    .ifEmpty { "Information" }
            }.asText()
    }
}