package content

import com.londogard.partyplanner.Contacts
import componentFrame
import dev.fritz2.components.icon
import dev.fritz2.dom.html.RenderContext
import dev.fritz2.styling.div
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import stores.DataStore
import kotlin.time.ExperimentalTime

@ExperimentalTime
@ExperimentalCoroutinesApi
fun RenderContext.contactContent(contacts: Flow<Contacts>) {
    if (!DataStore.isAuthorized()) {
        unauthorizedContent()
        return
    }

    div {
        contacts
            .map { it.entries }
            .renderEach { contactWrapper ->
                div {
                    h1 { +(contactWrapper.title.ifEmpty { "-" }) }
                    componentFrame(padding = { normal }, { margin { small } }) {
                        contactWrapper
                            .entries
                            .sortedByDescending { it.telephone?.length }
                            .forEach { contact ->
                                div {
                                    h2 { +contact.name }
                                    div({paddings { left { normal } }}) {
                                        if (!contact.telephone.isNullOrEmpty()) {
                                            a {
                                                href("tel:${contact.telephone}")
                                                icon({ margins { right { tiny } } }) { fromTheme { call } }
                                                +contact.telephone
                                            }
                                        }
                                        if (contact.email != null) {
                                            br { }
                                            a {
                                                href("mailto:${contact.email}")
                                                icon({ margins { right { tiny } } }) { fromTheme { email } }
                                                +contact.email
                                            }
                                        }

                                        if (contact.address != null) {
                                            br { }
                                            p {
                                                icon({ margins { right { tiny } } }) { fromTheme { home } }
                                                +contact.address
                                            }
                                        }
                                    }
                                }
                            }

                    }
                }
            }
    }
}