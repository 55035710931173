import dev.fritz2.components.box
import dev.fritz2.dom.html.Div
import dev.fritz2.dom.html.RenderContext
import dev.fritz2.styling.params.FlexParams
import dev.fritz2.styling.params.ScaledValueProperty
import kotlinx.coroutines.ExperimentalCoroutinesApi

@ExperimentalCoroutinesApi
fun RenderContext.componentFrame(
    padding: ScaledValueProperty = { none },
    styling: FlexParams.() -> Unit = {},
    content: Div.() -> Unit
): Div {
    return box({
        width { "fit-content" }
        width { "-moz-fit-content;" }
        styling()
        css("box-sizing: border-box;")
        border {
            width { thin }
            color { gray300 }
        }
        radius { small }
        padding(padding)
    }, content = content)
}
