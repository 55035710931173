package components

import dev.fritz2.components.alert
import dev.fritz2.components.showToast
import kotlinx.coroutines.ExperimentalCoroutinesApi

@ExperimentalCoroutinesApi
object Toasts {
    fun showAlertToast(content: String? = null, title: String? = null, duration: Long = 5_000L) = showToast {
        hasCloseButton(false)
        duration(duration)
        content {
            alert({ margin { none } }) {
                severity { warning }
                title?.let(::title)
                content?.let(::content)
            }
        }
    }
}