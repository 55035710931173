package content

import dev.fritz2.binding.Handler
import dev.fritz2.binding.Store
import dev.fritz2.components.alert
import dev.fritz2.components.clickButton
import dev.fritz2.components.formControl
import dev.fritz2.components.showToast
import dev.fritz2.dom.html.RenderContext
import dev.fritz2.routing.Router
import dev.fritz2.styling.div
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.onEach
import simpleEncode
import stores.RestApiStore.testAuth
import kotlin.time.ExperimentalTime

@ExperimentalCoroutinesApi
fun invalidLoginToast() = showToast {
    background { danger.main }
    hasCloseButton(false)
    content {
        alert {
            title("Login Failed")
            content("Try again")
            severity { error }
        }
    }
}

@ExperimentalTime
@ExperimentalCoroutinesApi
fun RenderContext.loginContent(
    tmpUsername: Store<String>,
    tmpPw: Store<String>,
    setLogin: Handler<Pair<String, String>>,
    router: Router<String>
) = div({
    width { maxContent }
    margins { all { auto } }
}) {
    formControl {
        label("Username")
        inputField(value = tmpUsername) { type("text") }
    }
    formControl {
        label("Password")
        inputField(value = tmpPw) { type("password") }
    }
    clickButton {
        disabled(tmpUsername.data.combine(tmpPw.data) { user, pw -> pw.isEmpty() || user.isEmpty() })
        text("Log me in")
    }
        .map { tmpUsername.current to tmpPw.current }
        .filter { (username, pw) ->
            if (testAuth(username.simpleEncode(), pw.simpleEncode())) true
            else false.also { invalidLoginToast() }
        }.onEach { router.navTo("information") } handledBy setLogin

    br { }
    small {
        b { +"OBS: " }
        +"Det går att logga in automatiskt via QR-kod på inbjudan!"
    }
}