package stores

import com.londogard.partyplanner.Gift
import dev.fritz2.binding.RootStore
import dev.fritz2.components.showToast
import dev.fritz2.remote.Request
import dev.fritz2.remote.http
import dev.fritz2.tracking.tracker
import kotlinx.browser.localStorage
import kotlinx.coroutines.withTimeout
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import simpleEncode
import kotlin.time.ExperimentalTime

const val username = "username"
const val password = "password"
const val api = "https://londogard.com/wedding"


@ExperimentalTime
object RestApiStore :
    RootStore<Pair<String, String>>((localStorage[username] ?: "") to (localStorage[password] ?: "")) {
    private val httpApi = http(api)

    val trackGiftUpdate = tracker()

    private fun removeLogin(): Pair<String, String> {
        localStorage.removeItem(username)
        localStorage.removeItem(password)
        return "" to ""
    }

    val setLogin = handle<Pair<String, String>> { _, (tmpUser, tmpPw) ->
        val (user, pw) = tmpUser.simpleEncode() to tmpPw.simpleEncode()
        localStorage.setItem(username, user)
        localStorage.setItem(password, pw)

        user to pw
    }

    val testLogin = handle { (user, pw) ->
        if (testAuth(user, pw)) user to pw
        else removeLogin()
    }

    // TODO - Decide how API & Race Conditions should work. 422?
    val checkGift = handleAndEmit<Gift, Gift> { (user, pw), gift ->
        trackGiftUpdate.track(gift.title) {
            val response = httpApi
                .basicAuth(user, pw)
                .contentType(jsonContent)
                .body(Json.encodeToString(gift))
                .post("/gift/check?weddingId=${DataStore.current.weddingId}")

            if (response.status == 401.toShort()) { removeLogin() }

            if (response.ok) {
                emit(gift)
            } else {
                showToast { content { p { +"⚠️Failed to update gift." } } }
            }

            user to pw
        }
    }

    val authenticatedBaseHttp: () -> Request = {
        val (user, pw) = current
        http(api).basicAuth(user, pw)
    }

    suspend fun testAuth(user: String, pw: String): Boolean {
        return runCatching {
            withTimeout(5_000L) {
                httpApi
                    .basicAuth(user, pw)
                    .get()
                    .status == 200.toShort()
            }
        }.getOrDefault(false)
    }
}