package content

import com.londogard.partyplanner.Gift
import dev.fritz2.binding.RootStore
import dev.fritz2.binding.SubStore
import dev.fritz2.components.*
import dev.fritz2.dom.html.RenderContext
import dev.fritz2.styling.img
import disabledStyle
import emptyDiv
import href
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onEach
import stores.RestApiStore
import kotlin.time.ExperimentalTime

@ExperimentalTime
@ExperimentalCoroutinesApi
fun RenderContext.giftCard(gift: SubStore<List<Gift>, Gift>) {
    // TODO I need to get store to be smarter about gifts & history.
    val currentGift = gift.current
    RestApiStore.checkGift.filter { it.title == currentGift.title } handledBy gift.update

    val allChecked = gift.data.map { it.checkedGiftById.all { (checked, _) -> checked } }
    val numItemsLeft = currentGift.checkedGiftById
        .count { (checked, _) -> !checked }
        .let { numItems -> if (numItems > 1) "(x${numItems})" else "" }

    stackUp {
        spacing { small }
        items {
            lineUp({
                width { full }
                disabledStyle()
            }) {
                spacing { small }
                items {
                    attr("disabled", allChecked)

                    imgData(currentGift.img, currentGift.title)
                    description(currentGift, numItemsLeft)

                    if (currentGift.checkable) {
                        val numItemStore = RootStore(1)
                        val checkableItems = currentGift.checkedGiftById.count { (checked, _) -> !checked }
                        flexBox({
                            wrap { wrap }
                            margins { left { "auto !important" } }
                        }) {
                            clickButton({ minWidth { unset } }) {
                                disabled(allChecked)
                                size { small }
                                icon { check }
                                loading(RestApiStore.trackGiftUpdate(currentGift.title))
                            } handledBy modal {
                                placement { center }
                                content { close ->
                                    h2 { +"Är du säker?" }
                                    p { +"Det går inte att ångra sig!" }
                                    br { }
                                    selectField(
                                        {
                                            css("float: right;")
                                            width { "3rem" }
                                            css("visibility: ${if (checkableItems == 1) "hidden" else "visible"}")
                                        },
                                        value = numItemStore,
                                        items = (1..checkableItems).toList()
                                    ) { }
                                    clickButton({ css("float: right;") }) {
                                        text("Välj")
                                        icon { check }
                                    }.map {
                                        val (checked, nonChecked) = currentGift.checkedGiftById.partition { (checked, _) -> checked }
                                        val newChecked =
                                            nonChecked.take(numItemStore.current).map { (_, id) -> true to id }
                                        currentGift.copy(checkedGiftById = checked + nonChecked.drop(numItemStore.current) + newChecked)
                                    }.onEach { close.invoke() } handledBy RestApiStore.checkGift
                                }
                            }
                        }
                    }
                }
            }
            hr { inlineStyle("width: 75%; align-self: flex-end; height: 2px;") }
        }
    }
}

@ExperimentalCoroutinesApi
fun RenderContext.imgData(src: String?, title: String) = when (src) {
    null -> emptyDiv(100)
    else -> img({
        height { "75px;" }
        margins { left { "0;" } }
        css("object-fit: cover;")
    }) {
        width(100)
        src(src)
        alt(title)
    }
}

@ExperimentalCoroutinesApi
fun RenderContext.description(currentGift: Gift, numItemsLeft: String) =
    div {
        p {
            b(semiBold.name) { +currentGift.title }
            +" $numItemsLeft"
        }

        p { +currentGift.description }

        p {
            currentGift.links.dropLast(1).forEach { link -> href(link);+"," }
            currentGift.links.lastOrNull()?.let(::href)
        }
    }