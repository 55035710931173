package content

import com.londogard.partyplanner.Data
import com.londogard.partyplanner.Gift
import com.londogard.partyplanner.L
import dev.fritz2.binding.RootStore
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.dom.html.RenderContext
import dev.fritz2.styling.div
import dev.fritz2.styling.style
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import rawHtmlDiv
import stores.DataStore
import kotlin.time.ExperimentalTime

@ExperimentalCoroutinesApi
val largeLeftMargin by lazy { style { margins { left { large } } } }

@ExperimentalCoroutinesApi
val right by lazy {
    style {
        margins { right { normal } }
        css("margin-left: auto !important;")
    }
}

@ExperimentalCoroutinesApi
val semiBold by lazy { style { fontWeight { this.semiBold } } }

@ExperimentalTime
@ExperimentalCoroutinesApi
fun RenderContext.giftContent(dataStore: RootStore<Data>) = div {
    if (!DataStore.isAuthorized()) {
        unauthorizedContent()
        return@div
    }

    val giftStore = dataStore.sub(L.Data.gift)
    h2 { giftStore.data.map { it.title }.asText() }
    giftStore.data.renderElement { data -> rawHtmlDiv(data.description) }

    val hasNonCheckable = giftStore.data.filter { it.gifts.any { gift -> !gift.checkable } }
    val hasCheckable = giftStore.data.filter { it.gifts.any(Gift::checkable) }

    hasNonCheckable
        .render {
            br { }
            h3(largeLeftMargin.name) { +"Gåvor som alltid uppskattas" }
            br { }
            stackUp({ width { full } }) {
                spacing { small }
                items {
                    giftStore
                        .sub(L.GifteryList.gifts)
                        .renderEach { gift ->
                            div { if (!gift.current.checkable) giftCard(gift) else inlineStyle("width: 0px;") }
                        }
                }
            }
        }

    hasCheckable
        .render {
            br { }
            lineUp({ width { full } }) {
                spacing { full }
                items {
                    h3(largeLeftMargin.name) { +"Gåvor som är avcheckningsbara" }
                    p((semiBold + right).name) { +"Vi Köper" }
                }
            }
            br { }

            stackUp {
                spacing { small }
                items {
                    giftStore
                        .sub(L.GifteryList.gifts)
                        .renderEach { gift ->
                            div({ width { full } }) {
                                if (gift.current.checkable) giftCard(gift)
                                else inlineStyle("width: 0px;")
                            }
                        }
                }
            }
        }
}