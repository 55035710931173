import com.londogard.partyplanner.L
import content.unauthorizedContent
import dev.fritz2.components.clickButton
import dev.fritz2.components.formControl
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.dom.html.RenderContext
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.map
import stores.DataStore
import stores.DataType
import kotlin.time.ExperimentalTime

@ExperimentalTime
@ExperimentalCoroutinesApi
fun RenderContext.rsvpContent() {
    val rsvpList = DataStore.sub(L.Data.guests)

    rsvpList
        .data
        .render { l ->
            if (l.isEmpty() && !DataStore.isAuthorized()) {
                unauthorizedContent()
            } else if (l.isEmpty()) {
                p { +"Something is wrong, please contact dev@londogard.com" }
            }
        }

    rsvpList.renderEach { rsvp ->
        val extra = rsvp.sub(extraIterableLens)
        val commentStore = rsvp.sub(L.Guest.comment)
        val guestRsvps = rsvp.sub(L.Guest.rsvps)

        componentFrame(padding = { normal }, { width { "fit-content" } }) {
            stackUp {
                items {
                    guestRsvps.renderEach { guest ->
                        val isFirst = guest.id.endsWith("0")
                        lineUp({ alignItems { stretch } }) {
                            spacing { tiny }
                            items {
                                formControl {
                                    if (isFirst) {
                                        label("Person")
                                        labelStyle { fontWeight { bolder } }
                                    }
                                    inputField(
                                        { disabled { textDecoration { "line-through" } } },
                                        value = guest.sub(L.RsvpGuest.name)
                                    ) {
                                        readonly(true)
                                        disabled(guest.data.map { person -> !person.isComing })
                                    }
                                }
                                formControl({
                                    width { "fit-content" }
                                    margin { auto }
                                }) {
                                    if (isFirst) {
                                        label("Kommer")
                                        labelStyle { fontWeight { bolder } }
                                    }
                                    checkbox({ radius { "50%" } }, value = guest.sub(L.RsvpGuest.isComing)) {
                                        size { large }
                                    }
                                }
                            }
                        }
                    }
                    div {
                        p { +"(för sent att ändra sig)" }
                        extra.renderEach { contentStore ->
                            div {
                                formControl {
                                    checkbox(value = contentStore.sub(pairLensSecond())) {
                                        disabled(true)
                                        label(
                                            contentStore.data
                                                .map {
                                                    it.first
                                                        .replace("hotel", "Vi vill gärna vara med på en storbokning av hotellrum! \uD83C\uDFE8")
                                                        .replace("bus", "Vi vill gärna ha transport in till Värnamo efter festens slut! \uD83D\uDE90")
                                                }
                                        )
                                    }
                                }
                            }
                        }
                        br { }
                        formControl {
                            label("Kommentar (matpreferens, speciella önskemål m.m.):")
                            textArea(value = commentStore)
                        }

                        clickButton {
                            loading(DataStore.tracker.data)
                            loadingText("Skickar..")
                            icon { check }
                            iconPlacement { left }
                            text("Submit")
                        } handledBy DataStore.submitRsvp
                    }
                }
            }
        }
    }
}