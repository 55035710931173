// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package com.londogard.partyplanner

import dev.fritz2.lenses.Lens
import dev.fritz2.lenses.buildLens
import kotlin.Boolean
import kotlin.Long
import kotlin.Pair
import kotlin.String
import kotlin.collections.List
import kotlin.collections.Map

public object L {
  public object ContactEntry {
    public val address: Lens<com.londogard.partyplanner.ContactEntry, String?> =
        buildLens("address", { it.address }, { p, v -> p.copy(address = v)})

    public val email: Lens<com.londogard.partyplanner.ContactEntry, String?> = buildLens("email", {
        it.email }, { p, v -> p.copy(email = v)})

    public val name: Lens<com.londogard.partyplanner.ContactEntry, String> = buildLens("name", {
        it.name }, { p, v -> p.copy(name = v)})

    public val telephone: Lens<com.londogard.partyplanner.ContactEntry, String?> =
        buildLens("telephone", { it.telephone }, { p, v -> p.copy(telephone = v)})
  }

  public object Contacts {
    public val entries: Lens<com.londogard.partyplanner.Contacts,
        List<com.londogard.partyplanner.ContactWrapper>> = buildLens("entries", { it.entries }, { p,
        v -> p.copy(entries = v)})
  }

  public object ContactWrapper {
    public val entries: Lens<com.londogard.partyplanner.ContactWrapper,
        List<com.londogard.partyplanner.ContactEntry>> = buildLens("entries", { it.entries }, { p,
        v -> p.copy(entries = v)})

    public val title: Lens<com.londogard.partyplanner.ContactWrapper, String> = buildLens("title", {
        it.title }, { p, v -> p.copy(title = v)})
  }

  public object Data {
    public val contact: Lens<com.londogard.partyplanner.Data, com.londogard.partyplanner.Contacts> =
        buildLens("contact", { it.contact }, { p, v -> p.copy(contact = v)})

    public val customMarkdownSections: Lens<com.londogard.partyplanner.Data, Map<String, String>?> =
        buildLens("customMarkdownSections", { it.customMarkdownSections }, { p, v ->
        p.copy(customMarkdownSections = v)})

    public val gift: Lens<com.londogard.partyplanner.Data, com.londogard.partyplanner.GifteryList> =
        buildLens("gift", { it.gift }, { p, v -> p.copy(gift = v)})

    public val guests: Lens<com.londogard.partyplanner.Data, List<com.londogard.partyplanner.Guest>>
        = buildLens("guests", { it.guests }, { p, v -> p.copy(guests = v)})

    public val information: Lens<com.londogard.partyplanner.Data,
        com.londogard.partyplanner.Information> = buildLens("information", { it.information }, { p,
        v -> p.copy(information = v)})

    public val weddingId: Lens<com.londogard.partyplanner.Data, Long> = buildLens("weddingId", {
        it.weddingId }, { p, v -> p.copy(weddingId = v)})
  }

  public object Gift {
    public val checkable: Lens<com.londogard.partyplanner.Gift, Boolean> = buildLens("checkable", {
        it.checkable }, { p, v -> p.copy(checkable = v)})

    public val checkedGiftById: Lens<com.londogard.partyplanner.Gift, List<Pair<Boolean, Long>>> =
        buildLens("checkedGiftById", { it.checkedGiftById }, { p, v -> p.copy(checkedGiftById = v)})

    public val description: Lens<com.londogard.partyplanner.Gift, String> = buildLens("description",
        { it.description }, { p, v -> p.copy(description = v)})

    public val img: Lens<com.londogard.partyplanner.Gift, String?> = buildLens("img", { it.img }, {
        p, v -> p.copy(img = v)})

    public val links: Lens<com.londogard.partyplanner.Gift, List<String>> = buildLens("links", {
        it.links }, { p, v -> p.copy(links = v)})

    public val title: Lens<com.londogard.partyplanner.Gift, String> = buildLens("title", { it.title
        }, { p, v -> p.copy(title = v)})
  }

  public object GifteryList {
    public val description: Lens<com.londogard.partyplanner.GifteryList, String> =
        buildLens("description", { it.description }, { p, v -> p.copy(description = v)})

    public val gifts: Lens<com.londogard.partyplanner.GifteryList,
        List<com.londogard.partyplanner.Gift>> = buildLens("gifts", { it.gifts }, { p, v ->
        p.copy(gifts = v)})

    public val title: Lens<com.londogard.partyplanner.GifteryList, String> = buildLens("title", {
        it.title }, { p, v -> p.copy(title = v)})
  }

  public object Guest {
    public val comment: Lens<com.londogard.partyplanner.Guest, String> = buildLens("comment", {
        it.comment }, { p, v -> p.copy(comment = v)})

    public val extra: Lens<com.londogard.partyplanner.Guest, Map<String, Boolean>> =
        buildLens("extra", { it.extra }, { p, v -> p.copy(extra = v)})

    public val guestid: Lens<com.londogard.partyplanner.Guest, Long> = buildLens("guestid", {
        it.guestid }, { p, v -> p.copy(guestid = v)})

    public val rsvps: Lens<com.londogard.partyplanner.Guest,
        List<com.londogard.partyplanner.RsvpGuest>> = buildLens("rsvps", { it.rsvps }, { p, v ->
        p.copy(rsvps = v)})

    public val userid: Lens<com.londogard.partyplanner.Guest, Long> = buildLens("userid", {
        it.userid }, { p, v -> p.copy(userid = v)})
  }

  public object Information {
    public val content: Lens<com.londogard.partyplanner.Information, String> = buildLens("content",
        { it.content }, { p, v -> p.copy(content = v)})

    public val date: Lens<com.londogard.partyplanner.Information, String> = buildLens("date", {
        it.date }, { p, v -> p.copy(date = v)})

    public val timeline: Lens<com.londogard.partyplanner.Information,
        List<com.londogard.partyplanner.TimelineElement>> = buildLens("timeline", { it.timeline }, {
        p, v -> p.copy(timeline = v)})
  }

  public object RsvpGuest {
    public val isComing: Lens<com.londogard.partyplanner.RsvpGuest, Boolean> = buildLens("isComing",
        { it.isComing }, { p, v -> p.copy(isComing = v)})

    public val name: Lens<com.londogard.partyplanner.RsvpGuest, String> = buildLens("name", {
        it.name }, { p, v -> p.copy(name = v)})
  }

  public object TimelineElement {
    public val description: Lens<com.londogard.partyplanner.TimelineElement, String> =
        buildLens("description", { it.description }, { p, v -> p.copy(description = v)})

    public val time: Lens<com.londogard.partyplanner.TimelineElement, String> = buildLens("time", {
        it.time }, { p, v -> p.copy(time = v)})

    public val timelineid: Lens<com.londogard.partyplanner.TimelineElement, Long?> =
        buildLens("timelineid", { it.timelineid }, { p, v -> p.copy(timelineid = v)})

    public val title: Lens<com.londogard.partyplanner.TimelineElement, String> = buildLens("title",
        { it.title }, { p, v -> p.copy(title = v)})
  }
}
