import dev.fritz2.dom.html.RenderContext
import dev.fritz2.styling.iframe
import kotlinx.coroutines.ExperimentalCoroutinesApi

@ExperimentalCoroutinesApi
fun RenderContext.googleMapsVarnamoKyrka() {
    iframe({css("box-sizing: border-box;")}) {
        src("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2162.2566229524446!2d14.04644131595802!3d57.18395158096025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4650c0e5dc029983%3A0xf10c942c3740b788!2sV%C3%A4rnamo%20kyrka!5e0!3m2!1ssv!2sse!4v1630849221520!5m2!1ssv!2sse")
        attr("loading", "lazy")
        height("300")
    }
}

@ExperimentalCoroutinesApi
fun RenderContext.googleMapsMadame() {
    iframe({css("box-sizing: border-box;")}) {
        src("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4324.129985306762!2d14.03829814819312!3d57.18722598279702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4650c0f0f4a0b43b%3A0xa017eed6456c29ac!2sMadame%20Brasserie%20%26%20Caf%C3%A9!5e0!3m2!1ssv!2sse!4v1630849156111!5m2!1ssv!2sse")
        attr("loading", "lazy")
        height("300")
    }
}