package stores

import com.londogard.partyplanner.Contacts
import com.londogard.partyplanner.Data
import com.londogard.partyplanner.GifteryList
import com.londogard.partyplanner.Information
import components.Toasts
import dev.fritz2.binding.RootStore
import dev.fritz2.components.alert
import dev.fritz2.components.showToast
import dev.fritz2.remote.getBody
import dev.fritz2.tracking.tracker
import kotlinx.browser.localStorage
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.map
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import kotlin.time.ExperimentalTime

@ExperimentalCoroutinesApi
@ExperimentalTime
val StartingData = localStorage["data"]
    ?.let { dataString ->
        DataStore.dataType = DataType.CACHED
        Json.decodeFromString(dataString)
    }
    ?: Data(Contacts(), Information("TBA", "TBA"), emptyList(), GifteryList("TBA", "TBA"), -1L)
        .also { DataStore.dataType = DataType.UNAUTHORIZED }
const val jsonContent = "application/json"

enum class DataType {
    AUTHORIZED, UNAUTHORIZED, CACHED
}

@ExperimentalCoroutinesApi
@ExperimentalTime
object DataStore : RootStore<Data>(StartingData) {
    val tracker = tracker()
    var dataType = DataType.CACHED

    fun isAuthorized(): Boolean = dataType != DataType.UNAUTHORIZED

    val getData = handle {
        val baseRequest = RestApiStore.authenticatedBaseHttp()
            .acceptJson()
            .contentType(jsonContent)
        val body = (kotlin
            .runCatching { baseRequest.get() }
            .map { it.getBody() }
            .onSuccess { data ->
                localStorage.setItem("data", data)
                dataType = DataType.AUTHORIZED
            }
            .getOrNull() ?: kotlin.runCatching { baseRequest.get("/unauthorized?weddingId=1") }
            .onSuccess { dataType = DataType.UNAUTHORIZED }.getOrNull()?.getBody()) ?: throw Exception("Failed to req")

        Json.decodeFromString(body)
    }

    // TODO 2. add follow-up with submission (that it works / not. handleAndEmit)
    val submitRsvp = handle { data ->
        val guest = data.guests.first()
        val resp = tracker.track("submit") {
            RestApiStore.authenticatedBaseHttp()
                .contentType(jsonContent)
                .body(Json.encodeToString(guest))
                .post("/guest/rsvp?weddingId=${data.weddingId}")
        }

        if (!resp.ok) {
            TODO("DID NOT WORK")
        }

        data
    }

    fun showToastForDataType() {
        when (dataType) {
            DataType.UNAUTHORIZED -> Toasts.showAlertToast("Unauthorized Content", "OBS: ")
            DataType.CACHED -> Toasts.showAlertToast("Cached (old) content", "OBS: ")
            DataType.AUTHORIZED -> showToast {
                hasCloseButton(false)
                content {
                    alert({ margin { none } }) {
                        severity { success }
                        content("Inloggad!")
                    }
                }
            }
        }
    }

    init {
        RestApiStore.data.map {  } handledBy getData
    }
}