package content

import com.londogard.partyplanner.Information
import components.timelineFlex
import dev.fritz2.components.*
import dev.fritz2.dom.html.RenderContext
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import rawHtmlDiv
import stores.DataStore
import stores.DataType
import kotlin.time.ExperimentalTime

@OptIn(ExperimentalTime::class)
@ExperimentalCoroutinesApi
fun RenderContext.mainContent(information: Flow<Information>) {
    div {
        h2 { +"Bröllop x Londögård" }
        p { +"Välkommen på bröllop! Kul att du kom till hemsidan! \uD83D\uDE03" }
        p { +"\uD83D\uDC48 Se till sidan för mer information gällande lokaler, OSA med mera!" }
        br {  }

        val isUnauthorized = !DataStore.isAuthorized()

        if (isUnauthorized) {
            unauthorizedContent()
        }

        information
            .renderElement(preserveOrder = false) { info ->
                stackUp {
                    items {

                        if (!isUnauthorized) {
                            timelineFlex(info.timeline)
                        }

                        rawHtmlDiv(info.content)
                    }
                }
            }
    }
}