package components

import com.londogard.partyplanner.TimelineElement
import componentFrame
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.popover
import dev.fritz2.dom.html.RenderContext
import dev.fritz2.styling.div
import dev.fritz2.styling.p
import kotlinx.coroutines.ExperimentalCoroutinesApi

@ExperimentalCoroutinesApi
fun RenderContext.timelineFlex(timelineElements: List<TimelineElement>) {
    if (timelineElements.isNotEmpty()) {
        div({
            position { relative { } }
            width { full }
        }) {
            div({
                position { absolute { } }
                mdProperties.append("top: 50%;left: 0%;")
                smProperties.append("left: 50%;")
                background { color { danger.main } }
                zIndex { "0" }
                width(sm = { "2px" }, md = { full })
                height(sm = { full }, md = { "2px" })
            }) { }

            flexBox({
                direction(sm = { column }, md = { row })
                justifyContent { spaceEvenly }
                position { relative { } }
            }) {
                timelineElements
                    .forEachIndexed { index, element ->
                        componentFrame(padding = { smaller }, {
                            css("align-self: center;")
                            textAlign { center }
                            margin { tiny }
                            background { color { primary.highlight } }
                        }) {
                            lineUp({
                                justifyContent { spaceBetween }
                            }) {
                                items {
                                    h4 { +element.time }
                                    popover({ textAlign { left } }) {
                                        placement { bottom }
                                        toggle { icon { fromTheme { circleInformation } } }
                                        header("${element.time} - ${element.title}")
                                        content {
                                            p({ margin { smaller } }) { +element.description }
                                        }
                                    }
                                }
                            }
                            h3 { +element.title }
                        }
                    }
            }
        }
    }
}