package content

import componentFrame
import dev.fritz2.components.flexBox
import dev.fritz2.dom.html.RenderContext
import googleMapsVarnamoKyrka
import googleMapsMadame
import href
import kotlinx.coroutines.ExperimentalCoroutinesApi

@ExperimentalCoroutinesApi
fun RenderContext.mapContent() {
    flexBox({ wrap { wrap } }) {
        componentFrame({ normal }, { margin { small } }) {
            flexBox({
                wrap { wrap }
                children(" > :not(:first-child)") { margin { small } }
            }) {
                div {
                    h3 { +"Värnamo Kyrka" }
                    href("http://maps.apple.com/?daddr=Värnamo+Kyrka", "\uD83D\uDDFA️Direktlänk")
                    p { +"Vi ses i Värnamo Kyrka för vigseln." }
                }
                googleMapsVarnamoKyrka()
            }
        }

        componentFrame({ normal }, { margin { small } }) {
            flexBox({
                wrap { wrap }
                children(" > :not(:first-child)") { margin { small } }
            }) {
                div {
                    h3 { +"Madame Brasserie & Café" }
                    href("http://maps.apple.com/?daddr=Madame+Brasserie", "\uD83D\uDDFA️Direktlänk")
                    p {
                        +"På Madame Brasserie & Café ses vi för bröllopsfesten"
                    }
                }
                googleMapsMadame()
            }
        }
    }
}