package content

import dev.fritz2.components.menu
import dev.fritz2.components.menu.MenuComponent
import dev.fritz2.dom.html.RenderContext
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Theme
import kotlinx.coroutines.ExperimentalCoroutinesApi
import stores.DataStore
import stores.DataType
import kotlin.time.ExperimentalTime

@ExperimentalTime
@ExperimentalCoroutinesApi
fun RenderContext.navContent(customSections: Iterable<String>) {
    menu {
        makeNavLink(Theme().icons.clipboardList, "Information", "#information")

        if (DataStore.isAuthorized()) {
            makeNavLink(Theme().icons.camera, "Fotografier", "#photos")
            makeNavLink(Theme().icons.call, "Kontaktinformation", "#contact")
            makeNavLink(Theme().icons.location, "Navigation", "#navigation")
            makeNavLink(Theme().icons.check, "OSA", "#rsvp")
            makeNavLink(Theme().icons.tag, "Bröllopsgåvor", "#gifts")
            customSections.forEach { key ->
                makeNavLink(Theme().icons.favorite, key.replaceFirstChar { it.titlecase() }, key)
            }
        } else {
            makeNavLink(Theme().icons.location, "Navigation", "#navigation")
            makeNavLink(Theme().icons.unlock, "Log In", "#login")
        }
    }
}

@ExperimentalCoroutinesApi
fun MenuComponent.makeNavLink(
    icon: IconDefinition,
    title: String,
    route: String,
) {
    link {
        text(title)
        icon { icon }
        href(route)
    }
}