import com.londogard.partyplanner.Guest
import dev.fritz2.dom.html.Div
import dev.fritz2.dom.html.RenderContext
import dev.fritz2.lenses.Lens
import dev.fritz2.lenses.buildLens
import dev.fritz2.routing.encodeURIComponent
import dev.fritz2.styling.a
import kotlinx.coroutines.ExperimentalCoroutinesApi

val regex: Regex = "https?://(www\\.)?([^/]+)".toRegex()
fun String.coreHtml(): String {
    return regex.find(this)?.groups?.get(2)?.value ?: this
}

val byteRegex = "%([0-9A-F]{2})".toRegex()
fun String.simpleEncode(): String {
    return byteRegex.replace(encodeURIComponent(this)) { m ->
        m.groupValues.last().toInt(16).toChar().toString()
    }
}

val extraIterableLens: Lens<Guest, List<Pair<String, Boolean>>> =
    buildLens("extra", { it.extra.toList() }, { p, v -> p.copy(extra = v.toMap())})
fun <K, V> pairLensSecond(): Lens<Pair<K, V>, V> =
    buildLens("pairSecond", { it.second }, { p, v -> p.copy(second = v)})

@ExperimentalCoroutinesApi
fun RenderContext.href(url: String, customName: String? = null) = a({ color { primary.main } }) {
    href(url)
    +(customName ?: url.coreHtml())
    target("_blank")
}

fun RenderContext.emptyDiv(width: Int): Div = div {
    inlineStyle("width: ${width}px; min-width: ${width}px; margin-left: 0;")
}

fun RenderContext.rawHtmlDiv(rawHtml: String): Div = div { domNode.innerHTML = rawHtml }
